import React, { Component } from 'react';

/* THIS PAGE HAS BEEN MOVED TO WRITING.HUDL */
export default class LabelsNonLabelText extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate(
      'http://hudl.com/design/writing/writing-for/teams#product'
    );
  }

  render() {
    return <div />;
  }
}
